


























































$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
.menu-label {
  font-size: 11px;
  letter-spacing: 1px;
}
.with-menus {
  background: #fff;
}
