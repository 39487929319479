



















.logo {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
