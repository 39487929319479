$color-0: #FAFAFA;
$color-1: #A458B8;
$color-2: #252C7D;
$color-3: #454B69;
$color-4: #4B61B1;
$color-5: #53B14B;
$color-6: #FF9494;
$color-7: #817F99;
$color-8: #E474A3;
$color-9: #41464b;

$gradient-1: linear-gradient(180deg, $color-1 0%, $color-2 100%);
$shadow-1: 4px 4px 4px rgba(0, 0, 0, 0.25);
$shadow-inset-1: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
$radius-1: 25px;
$radius-2: 20px;
$radius-3: 30px;
$radius-4: 35px;
$radius-5: 40px;
$radius-6: 45px;
$radius-7: 50px;
$gap-1: 0.0625rem;
$gap-2: 0.125rem;
$gap-3: 0.25rem;
$gap-4: 0.5rem;
$gap-5: 1rem;
$gap-6: 2rem;
$font-sm: 0.9rem;
$font-m: 1rem;

$nue-shadow-1: rgba(255, 249, 191, 0.5);

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p,label,input,textarea,button,a {
  font-family: 'Lato', sans-serif !important;
}

a, .link {
  font-size: $font-sm;
  color: $color-3;
  text-decoration: none;
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: pre-wrap;
}

.clickable, .link {
  cursor: pointer;
}

.dim {
  opacity: 0.5;
}

.radius-1 {
  border-radius: $radius-1;
}

// background color begins
.bg-color-0 {
  background-color: $color-0 !important;
}
.bg-color-1 {
  background-color: $color-1 !important;
}
.bg-color-2 {
  background-color: $color-2 !important;
}
.bg-color-3 {
  background-color: $color-3;
}
.bg-color-4 {
  background-color: $color-4;
}
.bg-color-5 {
  background-color: $color-5;
}
.bg-color-6 {
  background-color: $color-6;
}
.bg-color-7 {
  background-color: $color-7;
}
.bg-color-8 {
  background-color: $color-8;
}
.bg-color-9 {
  background-color: $color-9;
}
// background color ends

// text color begins
.color-0 {
  color: $color-0;
}
.color-1 {
  color: $color-1;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}
.color-4 {
  color: $color-4;
}
.color-5 {
  color: $color-5;
}
.color-6 {
  color: $color-6;
}
.color-7 {
  color: $color-7;
}
.color-8 {
  color: $color-8;
}
.color-9 {
  color: $color-9;
}
// text color ends

// shadow begins
.shadow-1 {
  box-shadow: $shadow-1;
}
.shadow-inset-1 {
  box-shadow: $shadow-inset-1;
}
// shadow ends

.page {
  margin-top: 0;
  margin-left: 0;
  height: calc(100vh - 64px);
  overflow-y: auto;
  &.home {
    width: 100%;
    padding-top: 0px;
    .home-logo-wrapper {
      margin: 50px 0 50px 0;
      svg {
        height: 200px;
      }
    }
    .how-to-video-wrapper {
      border-radius: $radius-1;
    }
    .how-to-video {
      height: 200px;
      border-radius: $radius-1;
    }
    #section-2 {
      margin-top: 200px;
    }
  }
  &.is-from-app {
    height: calc(100vh - 0px) !important;
  }
}

.sidebar {
  // position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  // width: 64px;
  background: $gradient-1;
  border-radius: 0 $radius-1 $radius-1 0;
  z-index: 999;
}
.header {
  top: 0;
  left: 0;
  height: 4rem;
  // background-image: linear-gradient(to bottom, #fff 40%, transparent);
  width: 100%;
  z-index: 1000;
  :before {
    content: '';
    background-color: #fff;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -30px;
    top: 0px;
  }
}

ul {
  li {
    position: relative;
    svg {
      transition: 0.2s;
      transform: rotate(0deg);
    }
    &.open {
      svg {
        transition: 0.2s;
        transform: rotate(-180deg);
      }
    }
  }
  &.menu-wrapper {
    a, span {
      font-size: $font-m;
      text-decoration: none;
      color: $color-2;
    }
    .child-menu-contianer {
      display: block;
      position: fixed;
      border-radius: 0 0 $radius-1 $radius-1;
      background-color: #fff;
    }
  }
}

.menu-img {
  width: 100%;
  height: 7rem;
  border-radius: 0 $radius-1 $radius-1 $radius-1;
  box-shadow: $shadow-1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.menu-desc {
  letter-spacing: 0.05rem;
  line-height: 1.2rem;
  font-size: $font-sm;
}

button {
  background: #FFFFFF;
  box-shadow: -4px -4px 4px $nue-shadow-1, 2px 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: $radius-2;
  outline: none !important;
  border: none;
  transition: 0.2s;
}

.card-curved {
  border-radius: $radius-1 !important;
}

.required-text {
  color: $color-6;
  font-weight: 600;
}

.form-field-text-md, .form-select {
  font-size: 16px !important;
  padding: 11px 20px !important;
}

.text-md {
  font-size: 16px !important;
}

.input-group-select {
  select {
    box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    border-radius: $radius-1 !important;
    cursor: pointer;
  }
}

.input-group-input {
  background-color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
  .input-group-text {
    color: $color-7;
    background-color: transparent;
    border: none !important;
  }
  .form-control {
    border: none;
    background-color: transparent;
    border: none !important;
  }
  &.disabled {
    user-select: none;
    cursor: default;
    .input-group-text {
      background-color: #e9ecef;
    }
    &:after {
      content: '';
      user-select: none;
      cursor: default;
    }
  }
}

.input-group-textarea {
  textarea {
    box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: $radius-1 !important;
  }
}

.btn-group {
  .rounded-pill {
    &.btn-group-choice-lg {
      height: 46px;
    }
    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.datepicker-component {
  input {
    padding: 0 20px;
    box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 46px;
    border-radius: 25px;
    border: none;
    outline: none;
  }
}

.validation-msg {
  color: $color-6;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.toast:not(.showing):not(.show) {
  opacity: 1 !important;
}

.hero-liquid {
  svg {
    width: 100%;
    height: 100%;
  }
}

#c {
  margin: 0 auto;
  display: block;
}

.home-container {
  height: auto;
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.btn-icon {
  svg {
    width: 22px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.go-down-wrapper {
  top: 30px;
  .go-down {
    position: relative;
    display: inline-block;
    top: 20px;
    left: calc(50% - 23px);
    width: 46px;
    height: 46px;
    border: 1px solid $color-4;
    border-radius: 100%;
    box-sizing: border-box;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 44px;
      height: 44px;
      box-shadow: #54b14b60;
      border-radius: 100%;
      opacity: 0;
      animation: sdb03 2s infinite;
      box-sizing: border-box;
    }
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      width: 16px;
      height: 16px;
      margin: -12px 0 0 -8px;
      border-left: 1px solid $color-4;
      border-bottom: 1px solid $color-4;
      transform: rotate(-45deg);
      box-sizing: border-box;
    }
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px #54b14b60;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

a {
  &.router-link {
    color: #9b9b9b;
    &.router-link-exact-active {
      &.router-link-active {
        color: #6610f2;
        font-weight: 600;
      }
    }
  }
}

.page-inner {
  width: calc(100% - 15px);
}

.form-select-md {
  padding: 6px 12px !important;
}

.loader {
  animation: spin 1s infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-form-control {
  padding: 0;
  width: 100%;
  border: none;
  color: #737373;
  font-size: 14px;
  outline: none;
}

.video-title {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.vdp-datepicker {
  input[type="text"] {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none !important;
  }
}

.swap-box-ul {
  li {
    .swap-box-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      background-color: #fff;
      box-sizing: border-box;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: $shadow-1;
      .swap-box-left {
        margin-right: 0;
        width: 100%;
        .swap-box-image {
          width: 100%;
          height: 300px;
          overflow: hidden;
          background-size: cover;
          background-position: center;
          img {
            width: 250px;
          }
        }
      }
      .swap-box-right {
        margin: 20px 20px 20px 20px;
        text-align: left;
        .swap-box-head {
          margin-bottom: 30px;
          font-weight: 500;
        }
        .swap-box-desc {
          font-size: 16px;
        }
        .swap-box-link {
          position: relative;
          padding-bottom: 2px;
          border: none;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
    &:nth-child(even) {
      .swap-box-wrapper {
        display: flex;
        flex-direction: row-reverse;
        .swap-box-left {
          margin-left: 0;
          margin-right: 0;
        }
        // .swap-box-right {
        //   margin: 20px 20px 20px 20px;
        //   text-align: left;
        // }
      }
    }
  }
}

.banner {
  .banner-side-image {
    max-height: 300px;
  }
}

.month-picker-input {
  padding: 8px 10px !important;
}

.month-picker__container {
  background-color: #fff;
}

.home-page {
  margin-top: 100px;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.w-100 > .month-picker-input {
  width: 100%;
}

.filter-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
}

.right-align {
  .month-picker__container {
    right: 0;
  }
}

.video-player {
  iframe {
    width: 100%;
    height: 220px;
  }
}

.icon-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
  }
}

.text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-max-100 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}

.tooltip {
  position: fixed !important;
  opacity: 1 !important;
}

.video-placeholder {
  height: 350px !important;
  margin-bottom: 20px;
}

.mid {
  position: relative;
  z-index: 0;
}
