





















































































































@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import "./assets/css/reset.css";
// https://flexboxgrid.com/
// @import "./assets/css/flexboxgrid.min.css";
@import "./assets/css/bootstrap5.0.0-beta1.css";
@import "./assets/css/style.scss";
@import "./assets/css/responsive.scss";
@import 'vue-content-placeholders/src/styles.scss';
.sidebar-wrapper {
  z-index: 1000;
}
